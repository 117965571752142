<template>
  <div data-testid="calculator-keypad">
    <div
      class="grid gap-[1px] bg-n-600 rounded-xl overflow-hidden border border-n-600 font-bold"
      :class="{
        'grid-cols-4': mode === CalculatorKeypadMode.FULL,
        'grid-cols-3': mode !== CalculatorKeypadMode.FULL
      }"
    >
      <template v-if="mode === CalculatorKeypadMode.FULL">
        <div
          v-taphint="{ duration: '150ms', color: hintColor }"
          :class="[...keyClasses, 'text-g-500']"
          @click="$emit('action', CalculatorKeypadActions.BACKSPACE)"
        >
          <Icon name="delete-arrow" />
        </div>
        <div
          v-taphint="{ duration: '150ms', color: hintColor }"
          :class="[...keyClasses, 'text-g-500']"
          @click="$emit('action', CalculatorKeypadActions.DIVIDE2)"
        >
          {{ `/2` }}
        </div>
        <div
          v-taphint="{ duration: '150ms', color: hintColor }"
          :class="[...keyClasses, 'text-g-500']"
          @click="$emit('action', CalculatorKeypadActions.DIVIDE3)"
        >
          {{ `/3` }}
        </div>
        <div
          v-taphint="{ duration: '150ms', color: hintColor }"
          :class="[...keyClasses, 'text-g-500']"
          @click="$emit('action', CalculatorKeypadActions.DIVIDE)"
        >
          {{ `/` }}
        </div>
      </template>
      <div class="grid col-span-3 gap-[1px]">
        <div class="grid gap-[1px] grid-cols-3">
          <div
            v-for="key of numKeys"
            :key="key"
            v-taphint="{ duration: '150ms', color: hintColor }"
            :class="[...keyClasses, 'text-n-0']"
            @click="$emit('number', key)"
          >
            {{ key }}
          </div>
        </div>
      </div>
      <div
        v-if="mode === CalculatorKeypadMode.FULL"
        class="grid col-span-1 gap-[1px]"
      >
        <div
          v-taphint="{ duration: '150ms', color: hintColor }"
          :class="[...keyClasses, 'text-g-500']"
          @click="$emit('action', CalculatorKeypadActions.MULTIPLY)"
        >
          <Icon name="close" />
        </div>
        <div
          v-taphint="{ duration: '150ms', color: hintColor }"
          :class="[...keyClasses, 'text-g-500']"
          @click="$emit('action', CalculatorKeypadActions.SUBTRACT)"
        >
          <Icon name="minus" />
        </div>
        <div
          v-taphint="{ duration: '150ms', color: hintColor }"
          :class="[...keyClasses, 'text-g-500']"
          @click="$emit('action', CalculatorKeypadActions.ADD)"
        >
          <Icon name="plus" />
        </div>
      </div>
      <div
        v-if="
          [
            CalculatorKeypadMode.FULL,
            CalculatorKeypadMode.DOUBLE_ZERO
          ].includes(mode as typeof CalculatorKeypadModeType)
        "
        v-taphint="{ duration: '150ms', color: hintColor }"
        :class="[...keyClasses, 'text-n-0']"
        @click="$emit('action', CalculatorKeypadActions.DOUBLE_ZERO)"
      >
        {{ `00` }}
      </div>
      <div
        v-else-if="mode !== CalculatorKeypadMode.SIMPLE"
        v-taphint="{ duration: '150ms', color: hintColor }"
        :class="[...keyClasses, 'text-g-500']"
        @click="$emit('action', CalculatorKeypadActions.BACKSPACE)"
      >
        <Icon name="back-arrow" />
      </div>
      <div v-else :class="[...keyClasses, 'text-g-500']"></div>

      <div
        v-taphint="{ duration: '150ms', color: hintColor }"
        :class="[...keyClasses, 'text-n-0']"
        @click="$emit('number', 0)"
      >
        0
      </div>
      <div
        v-if="mode === CalculatorKeypadMode.SIMPLE"
        v-taphint="{ duration: '150ms', color: hintColor }"
        :class="[...keyClasses, 'text-g-500']"
        @click="$emit('action', CalculatorKeypadActions.BACKSPACE)"
      >
        <Icon name="back-arrow" />
      </div>
      <div
        v-else
        v-taphint="{ duration: '150ms', color: hintColor }"
        :class="[...keyClasses, 'text-g-500']"
        @click="$emit('action', CalculatorKeypadActions.CLEAR)"
      >
        <Icon name="calculator-c" />
      </div>
      <div
        v-if="mode === CalculatorKeypadMode.FULL"
        v-taphint="{ duration: '150ms', color: hintColor }"
        :class="[...keyClasses, 'text-g-500']"
        @click="$emit('action', CalculatorKeypadActions.EQUALS)"
      >
        <Icon name="equals" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  CalculatorKeypadActions,
  CalculatorKeypadMode,
  CalculatorKeypadModeType
} from '@/components/Calculator/CalculatorUtils.js'
import { computed } from 'vue'
import { Icon } from '@last/core-ui/paprika'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/../tailwind.config'
import { onKeyStroke } from '@vueuse/core'

type Props = {
  mode: CalculatorKeypadModeType
  focused?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  mode: CalculatorKeypadMode.SIMPLE,
  focused: false
})

const emit = defineEmits(['number', 'action'])

const numKeys = computed<number[]>(() => {
  let keys = Array.from({ length: 9 }, (_, i) => i + 1)
  if (props.mode !== CalculatorKeypadMode.SIMPLE) {
    keys = [7, 8, 9, 4, 5, 6, 1, 2, 3]
  }
  return keys
})

const keyClasses = [
  'select-none',
  'cursor-pointer',
  'bg-n-700',
  'hsm:h-[2rem]',
  'hmd:h-[3.125rem]',
  'h-[4.125rem]',
  'flex',
  'items-center',
  'justify-center'
]

const fullConfig = resolveConfig(tailwindConfig as any)

const hintColor = (fullConfig.theme.colors as any).n['600']

onKeyStroke(
  ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  e => {
    if (!props.focused) return
    e.preventDefault()
    emit('number', parseInt(e.key))
  },
  { dedupe: true }
)

onKeyStroke(
  ['Clear', 'Escape', 'Delete'],
  e => {
    if (!props.focused) return
    e.preventDefault()
    emit('action', CalculatorKeypadActions.CLEAR)
  },
  { dedupe: true }
)

onKeyStroke(
  'Backspace',
  e => {
    if (!props.focused) return
    e.preventDefault()
    emit('action', CalculatorKeypadActions.BACKSPACE)
  },
  { dedupe: true }
)
</script>

import { computed, MaybeRefOrGetter, toValue } from 'vue'
import { storeToRefs } from 'pinia'
import { useTabsStore } from '@/store/tabs'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { usePromotionsStore } from '@/store/promotions'
import Bills from '@last/core/src/billsGenerator.js'
import TicketPrinter from '@/ticketPrinter.js'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import { managerConfirmation } from '@/plugins/managerConfirmation'
import type {
  Bill,
  Tab,
  Courier,
  TabProduct,
  BillWithPayments,
  DeliveryStatus
} from '@/types'
import { useNotifications } from './useNotifications'
import { dialog } from '@last/core-ui/paprika/plugins/dialog/dialog'
import app from '@/app'

interface Product {
  notPaidQuantity: number
  notBilledQuantity: number
}

interface CourseProduct {
  name: string
  products: Product[]
}

export const useTabs = (tabId: MaybeRefOrGetter<string>) => {
  const tabsStore = useTabsStore()
  const config = useConfigStore()
  const { route, router, t } = tabsStore
  const { notifyTabClosed, notifyPaymentFailed } = useNotifications()
  const { tabs, bills, payments, products, kitchenOrders } =
    storeToRefs(tabsStore)

  const tab = computed<Tab>(() => {
    const id = toValue(tabId)
    return tabs.value[id]
  })

  const catalogId = computed(() => {
    if (!tab.value) return null
    const catalogStore = useCatalogStore()
    const { getCatalogIdByTabVirtualBrandId } = catalogStore
    return getCatalogIdByTabVirtualBrandId(
      tab.value.virtualBrandId,
      tab.value.pickupType!,
      tab.value.source!
    )
  })

  const getBills = computed((): BillWithPayments[] => {
    if (!tab.value) return []
    return tab.value.bills
      .map(billId => ({
        ...bills.value[billId],
        payments: bills.value[billId].payments.map(
          paymentId => payments.value[paymentId]
        )
      }))
      .map(bill =>
        Bills.addPaymentInfo(
          bill,
          bill.payments.filter(payment => !!payment)
        )
      )
  })

  const getPaidProductQuantities = computed(() => {
    if (!tab.value) return {}
    return getBills.value
      .flatMap(bill => {
        if (bill.pending === 0) {
          return bill.products
        } else {
          return []
        }
      })
      .reduce(
        (res, product) => {
          res[product.tabProductId] =
            (res[product.tabProductId] || 0) + product.quantity
          return res
        },
        {} as Record<string, number>
      )
  })

  const getBilledProductQuantities = computed(() => {
    if (!tab.value) return {}
    return getBills.value
      .flatMap(bill => bill.products)
      .reduce(
        (res, product) => {
          res[product.tabProductId] =
            (res[product.tabProductId] || 0) + product.quantity
          return res
        },
        {} as Record<string, number>
      )
  })

  const getSharedProducts = computed(() => {
    if (!tab.value) return []
    const billedProductQuantities = getBilledProductQuantities.value
    const paidProductQuantities = getPaidProductQuantities.value
    return (tab.value.shared ?? []).map(productId => ({
      ...products.value[productId],
      notBilledQuantity:
        products.value[productId].quantity -
        (billedProductQuantities[productId] || 0),
      notPaidQuantity:
        products.value[productId].quantity -
        (paidProductQuantities[productId] || 0)
    }))
  })

  const getSeatProducts = computed(() => {
    if (!tab.value) return []
    const billedProductQuantities = getBilledProductQuantities.value
    const paidProductQuantities = getPaidProductQuantities.value
    return (tab.value.seats ?? []).map(seat =>
      seat.map(productId => ({
        ...products.value[productId],
        notBilledQuantity:
          products.value[productId].quantity -
          (billedProductQuantities[productId] || 0),
        notPaidQuantity:
          products.value[productId].quantity -
          (paidProductQuantities[productId] || 0)
      }))
    )
  })

  const getAllProducts = computed((): TabProduct[] => {
    if (!tab.value) return []
    const { productsById } = useCatalogStore()
    const {
      config: { taxRate }
    } = useConfigStore()

    return [...getSharedProducts.value, ...getSeatProducts.value.flat()].map(
      product => {
        return {
          ...product,
          taxes: productsById[product.parentProduct]?.taxes || taxRate
        }
      }
    )
  })

  const getCourseProducts = computed(() => {
    if (!tab.value || !catalogId.value) return []
    const billedQuantities = getBilledProductQuantities.value
    const paidQuantities = getPaidProductQuantities.value
    const tabProducts = [
      ...(tab.value.shared ?? []).concat((tab.value.seats ?? []).flat())
    ]
    const catalogStore = useCatalogStore()
    const catalog = catalogStore.catalogs[catalogId.value]

    const courseProductsMap = tabProducts.reduce<Record<string, Product[]>>(
      (acc, productId) => {
        const product = products.value[productId]
        if (product.comboProducts)
          product.comboProducts.forEach(comboProduct => {
            const finalProduct = {
              ...product,
              shownCombo: comboProduct,
              notBilledQuantity:
                product.quantity - (billedQuantities[productId] || 0),
              notPaidQuantity:
                product.quantity - (paidQuantities[productId] || 0)
            }
            if (acc[comboProduct.course])
              acc[comboProduct.course].push(finalProduct)
            else acc[comboProduct.course] = [finalProduct]
          })
        else {
          const finalProduct = {
            ...product,
            notBilledQuantity:
              product.quantity - (billedQuantities[productId] || 0),
            notPaidQuantity: product.quantity - (paidQuantities[productId] || 0)
          }
          if (acc[product.course]) acc[product.course].push(finalProduct)
          else acc[product.course] = [finalProduct]
        }
        return acc
      },
      {}
    )

    const catalogCourses = catalog.courses
    const courseProducts: CourseProduct[] = []
    for (const course of catalogCourses) {
      courseProducts.push({
        name: course,
        products: courseProductsMap[course] || []
      })
      delete courseProductsMap[course]
    }

    courseProducts.push({
      name: t('tabs.other'),
      products: Object.values(courseProductsMap).flat()
    })
    return courseProducts
  })

  const getCustomerId = computed(() => {
    if (!tab.value) return null
    return tab.value.customerId
  })

  const getGlobalDiscount = computed(() => {
    if (!tab.value) return null
    const promotionsStore = usePromotionsStore()
    const promotion = promotionsStore.getTabGlobalPromotion(tab.value.id)
    return promotion
      ? {
          type: promotion.discountType,
          amount: promotion.discountAmount,
          freeDelivery: promotion.freeDelivery
        }
      : null
  })

  const getTotal = computed(() => {
    if (!tab.value) return null
    const generatedBill = Bills.generateProductsBill({
      products: getAllProducts.value,
      company: config.config.company,
      tab: tab.value,
      discount: getGlobalDiscount.value,
      taxRate: config.config.taxRate,
      ticketInfo: config.config.ticketInfo
    })
    return generatedBill.total
  })

  const getSentToKitchenProducts = computed(() => {
    if (!tab.value) return []
    return tab.value.kitchenOrders
      .map(id => kitchenOrders.value[id])
      .flatMap(order =>
        order.versions.slice(-1)[0].products.map(product => {
          return {
            productId: product.tabProductId,
            sent: order.creationTime,
            kitchenOrder: order.id
          }
        })
      )
  })

  const getUnsentProducts = computed((): TabProduct[] => {
    if (!tab.value) return []
    const sentProducts = getSentToKitchenProducts.value.map(
      product => product.productId
    )
    const tabProducts = getAllProducts.value
    return tabProducts
      .flatMap((product: TabProduct): TabProduct[] => {
        if (product.comboProducts) {
          return product.comboProducts.map(
            comboProduct =>
              ({
                ...comboProduct,
                quantity: comboProduct.quantity * product.quantity,
                fromCombo: true,
                combo: product
              }) as unknown as TabProduct
          )
        } else {
          return [product]
        }
      })
      .filter(product => !sentProducts.includes(product.id))
  })

  const hasPendingKitchenOrders = computed(() => {
    if (!config.config.enableKitchenOrders) return false
    return (
      getUnsentProducts.value.length > 0 ||
      tab.value.kitchenOrders
        .map(id => kitchenOrders.value[id])
        .filter(order => !order.printedTime && order.copies > 0).length > 0
    )
  })

  const notBilledProducts = computed(() => {
    return getAllProducts.value
      .map(product => {
        return {
          ...product,
          quantity: product.notBilledQuantity ?? 0
        }
      })
      .filter(product => product.quantity > 0)
  })

  const getPendingBill = computed<Bill>(() => {
    if (!tab.value) return null
    return Bills.generateProductsBill({
      products: notBilledProducts.value,
      company: config.config.company,
      tab: tab.value,
      discount: getGlobalDiscount.value,
      taxRate: config.config.taxRate,
      ticketInfo: config.config.ticketInfo
    })
  })

  const hasSentToKitchenProducts = computed(
    () => getSentToKitchenProducts.value?.length > 0
  )

  const getBillsDiscounts = computed(() => {
    if (!tab.value) return []
    const tabBills = [
      ...getBills.value,
      ...(getPendingBill.value ? [getPendingBill.value] : [])
    ]
    return tabBills
      .map(bill => {
        return {
          ...bill.discount,
          discountTotal: bill.discountTotal
        }
      })
      .filter(
        discount => discount && discount.amount && discount.discountTotal > 0
      )
  })

  const getProductsDiscount = computed(() => {
    return getAllProducts.value.reduce((discount, product) => {
      return discount + product.fullPrice - product.finalPrice
    }, 0)
  })

  const getDeliveryFee = computed(() => {
    const deliveryFee = { isFree: false, value: 0 }
    if (!tab.value) return deliveryFee
    const discount = getGlobalDiscount.value
    const deliveryOrder = tab.value.deliveryOrder
    if (discount && discount.freeDelivery) {
      deliveryFee.isFree = true
    } else if (deliveryOrder) {
      deliveryFee.value = deliveryOrder.deliveryFee || 0
    }
    return deliveryFee
  })

  function updateDeliveryOrderStatus({
    newStatus,
    courier
  }: {
    newStatus: DeliveryStatus
    courier?: Courier
  }) {
    tabsStore.updateDeliveryOrderStatus({
      tabId: tab.value.id,
      newStatus,
      courier
    })
    if (newStatus === 'DELIVERED') {
      closeTab()
    }
  }

  const getMinimumBasketSurcharge = computed(() => {
    return tab.value?.deliveryOrder?.minimumBasketSurcharge || 0
  })

  const getTotalPaid = computed(() => {
    return getBills.value
      .map(bill => Math.min(bill.paid, bill.total))
      .reduce((total, paid) => total + paid, 0)
  })

  const getTotalPending = computed(() => {
    const billsPending = getTotalWithoutPendingBill.value

    return billsPending + getPendingBill.value?.total
  })

  const getTotalWithoutPendingBill = computed(() => {
    return getBills.value
      .map(bill => bill.pending)
      .reduce((total, pending) => total + pending, 0)
  })

  function closeTabAndGoHome(withPin = false) {
    tabsStore.closeTab({ tabId: tab.value.id, closedWithPin: withPin })
    if (route.name !== 'pos') {
      router.push({ name: 'pos' })
    }
    notifyTabClosed(tab.value.id)
  }

  async function closeTabWithPermissionCheck() {
    const hasPermission = await managerConfirmation('CLOSE_TAB_AS_MANAGER')
    if (hasPermission) {
      closeTabAndGoHome(true)
    }
  }

  async function closeTab() {
    if (getTotalWithoutPendingBill.value > 0) {
      if (tab.value.deliveryOrder?.preferredPaymentMethod) {
        const paymentData = {
          billId: tab.value.bills[0],
          amount: getBills.value
            .flatMap(
              bill =>
                bill.total -
                bill.payments.reduce(
                  (total, payment) => payment.amount + total,
                  0
                )
            )
            .reduce((sum, billTotal) => sum + billTotal, 0),
          type: tab.value.deliveryOrder?.preferredPaymentMethod
        }
        if (CashMachine.methods.includes(paymentData.type)) {
          const charged = await CashMachine.charge(paymentData.amount)
          if (charged === 0) {
            notifyPaymentFailed()
            return
          }
        }
        tabsStore.addPayment({ ...paymentData, tabId: tab.value.id })
        closeTabAndGoHome()
      } else {
        dialog({
          title: t('close-tab.title'),
          content: t('close-tab.message-pending-payments'),
          mainLabel: t('close-tab.dismiss')
        })
      }
    } else if (notBilledProducts.value.length > 0) {
      if (hasSentToKitchenProducts.value) {
        dialog({
          title: t('close-tab.title'),
          content: t('close-tab.message-no-kitchen-orders'),
          mainLabel: t('close-tab.enter-pin'),
          onConfirm: closeTabWithPermissionCheck
        })
      } else {
        dialog({
          title: t('close-tab.title'),
          content: t('close-tab.message-kitchen-orders'),
          mainLabel: t('close-tab.close-it'),
          onConfirm: () => {
            closeTabAndGoHome()
          }
        })
      }
    } else {
      closeTabAndGoHome()
    }
  }

  function printBill() {
    const pendingBills = getBills.value.filter(bill => bill.pending > 0)
    if (pendingBills.length > 0) {
      pendingBills.forEach(bill => TicketPrinter.printBill(bill))
    } else {
      let bill = getPendingBill.value
      bill = Bills.addPaymentInfo(bill, [])
      TicketPrinter.printBill(bill, true)
      tabsStore.startBilling(tab.value.id)
    }
  }

  const getStatus = computed(() => {
    if (!tab.value.open) return 'closed'
    if (tab.value.billingStartedTime) return 'billing-started'
    if (!tab.value.activationTime) return 'scheduled'
    return 'open'
  })

  const isCancelled = computed(() => tab.value.deliveryOrder?.cancelTime)
  const hasAlert = computed(
    () =>
      tab.value.deliveryOrder?.errorTime &&
      !tab.value.deliveryOrder?.errorResolved
  )

  const hasNonBilledProducts = computed(
    () => notBilledProducts.value.length > 0
  )

  const isFullyPaid = computed(
    () => getTotalPending.value === 0 && !hasNonBilledProducts.value
  )

  const fastCheckoutAllowed = computed(() => {
    if (app.isMobile) return true
    let pendingBills = getBills.value.filter(bill => bill.pending > 0).length
    const pendingToBill = getAllProducts.value
      .map(product => ({
        ...product,
        quantity: product.notBilledQuantity
      }))
      .filter(product => product.quantity > 0)
    if (pendingToBill.length > 0) {
      pendingBills += 1
    }
    return pendingBills === 1
  })

  const extraCharges = computed(() => {
    return getBills.value
      .flatMap(bill => bill.payments)
      .filter(payment => payment.amount < 0)
      .map(payment => -payment.amount)
      .reduce((res, amount) => res + amount, 0)
  })

  const isDelivery = computed(() => {
    if (!tab.value) return false
    return (
      tab.value.pickupType &&
      ['delivery', 'ownDelivery'].includes(tab.value.pickupType)
    )
  })

  return {
    tab,
    catalogId,
    getAllProducts,
    getSharedProducts,
    getSeatProducts,
    getBills,
    getCustomerId,
    getTotal,
    getTotalPaid,
    getTotalPending,
    getTotalWithoutPendingBill,
    getCourseProducts,
    getSentToKitchenProducts,
    getUnsentProducts,
    getPendingBill,
    hasSentToKitchenProducts,
    hasNonBilledProducts,
    getBillsDiscounts,
    getDeliveryFee,
    getMinimumBasketSurcharge,
    getStatus,
    getProductsDiscount,
    getGlobalDiscount,
    isFullyPaid,
    hasPendingKitchenOrders,
    isCancelled,
    hasAlert,
    closeTab,
    printBill,
    updateDeliveryOrderStatus,
    fastCheckoutAllowed,
    extraCharges,
    isDelivery
  }
}

import KitchenOrderFormatter from '@/kitchenOrderFormatter.js'
import Printer from '@/integrations/printer/printer.js'
import i18n from './i18n'
import sync from '@/sync/service'
import api from '@/api'
import { useNotifications } from '@/composables/useNotifications'
import moment from 'moment'
import TicketGenerator from '@/tickets/generator.js'
import lastUtils from '../../core/src/lastUtils'
import app from '@/app'
import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import { useTillStore } from '@/store/till'
import { useCatalogStore } from '@/store/catalog'

function hasDirectPrint() {
  let config = useConfigStore()
  return !config.config.onlyMasterPrinting || config.device.mode === 'master'
}

function wakeupPrint(billId, value) {
  let call = wakeupCalls[billId]
  if (call) {
    call[value] = true
    if (call.valuesToCheck.every(val => call[val])) {
      call.wakeUp()
    }
  }
}

let wakeupCalls = {}

// https://stackoverflow.com/a/51843228
function wakeablePrintPromise(bill, valuesToCheck) {
  return new Promise(resolve => {
    wakeupCalls[bill.id] = {
      meta: !!bill.metadata,
      number: !!bill.number,
      valuesToCheck,
      wakeUp: () => resolve(true)
    }
  })
}

async function printKitchenOrder(order) {
  const { notifyInfo } = useNotifications()
  notifyInfo({
    title: i18n.global.t('notifications.sent-to-kitchen'),
    icon: 'kitchen'
  })
  if (!hasDirectPrint()) {
    sync.record('kitchenOrderAddedToPrintQueue', order.id)
    return
  }
  let tabs = useTabsStore()
  let tab = tabs.getTab(order.tabId)
  let catalog = useCatalogStore()
  let catalogId = catalog.getCatalogIdByTabVirtualBrandId(tab.virtualBrandId)
  let sortedCourses = catalog.catalogs[catalogId]?.courses || []
  let config = useConfigStore()
  let printer = config.config.printers[order.printerId] || {}
  let scale = 4
  let canvas = await TicketGenerator.kitchenOrder(
    KitchenOrderFormatter.format(
      { ...order, tableName: tab.tableName },
      sortedCourses
    ),
    printer,
    scale
  )
  let image = canvas.toDataURL('image/png')
  let printedEvent = null
  printedEvent = {
    name: 'kitchenOrderPrinted',
    data: { orderId: order.id }
  }
  Printer.printImage(order.printerId, image, order.copies, printedEvent)
}

async function printKitchenNote(courseLabel, tab) {
  let scale = 4
  let canvas = await TicketGenerator.kitchenNote(courseLabel, tab, scale)
  let image = canvas.toDataURL('image/png')

  let tabs = useTabsStore()
  const kitchenOrdersOnTab = tab.kitchenOrders.map(order => {
    return tabs.kitchenOrders[order]
  })

  const kitchenOrdersForCourse = kitchenOrdersOnTab.filter(order => {
    return order.versions.some(version => {
      return version.products.some(product => product.course === courseLabel)
    })
  })

  const printers = [
    ...new Set(
      kitchenOrdersForCourse.map(order => {
        return order.printerId
      })
    )
  ]

  printers.forEach(printerId => Printer.printImage(printerId, image, 1))
}

async function printCancelTabTicket(tab) {
  let scale = 4
  let canvas = await TicketGenerator.cancelTab(tab, scale)
  let image = canvas.toDataURL('image/png')
  let tabs = useTabsStore()
  let printers = [
    ...new Set(
      tab.kitchenOrders.map(order => {
        return tabs.kitchenOrders[order]?.printerId
      })
    )
  ]
  printers.map(printerId => Printer.printImage(printerId, image, 1))
}

async function printBill(bill, isPendingBill = false) {
  const { notifyInfo } = useNotifications()
  notifyInfo({
    title: i18n.global.t('notifications.printing-bill'),
    icon: 'ticket'
  })
  if (!hasDirectPrint()) {
    if (!isPendingBill) {
      sync.record('billAddedToPrintQueue', bill.id)
      return
    } else {
      sync.record('pendingBillAddedToPrintQueue', bill.tabId)
      return
    }
  }

  const configStore = useConfigStore()
  const config = configStore.config

  let valuesToCheck = []
  if (config.waitForMetaBeforePrintingBill && !bill.metadata)
    valuesToCheck.push('meta')
  if (config.organizationConfig.waitForNumberBeforePrintingBill && !bill.number)
    valuesToCheck.push('number')
  if (!isPendingBill && valuesToCheck.length > 0) {
    await Promise.race([
      wakeablePrintPromise(bill, valuesToCheck),
      lastUtils.sleep(3000)
    ])
    delete wakeupCalls[bill.id]
    let tabs = useTabsStore()
    let storedBill = tabs.getBillById(bill.id)
    bill = storedBill || bill
  }
  let options = {
    barcode: config.organizationConfig.barcodes,
    logoImageId: config.virtualBrands.find(v => v.id === bill.virtualBrandId)
      .imageId
  }
  let image = null
  let scale = 4
  let canvas = await TicketGenerator.bill(bill, options, scale)
  image = canvas.toDataURL('image/png')
  let printerId = getTargetPrinterId(bill.pickupType)
  let printedEvent = null
  if (!bill.printedTime && !isPendingBill) {
    printedEvent = { name: 'billPrinted', data: { billId: bill.id } }
  }
  Printer.printImage(printerId, image, config.billCopies, printedEvent)
}

async function printCourierReport(report) {
  let scale = 4
  let canvas = await TicketGenerator.courierReport(report, scale)
  let image = canvas.toDataURL('image/png')

  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printZReport(report) {
  let scale = 4
  if (!report) {
    report = (await api.get('/reports/z'))?.data
  }
  let config = useConfigStore()
  let canvas = await TicketGenerator.zReport(report, config.config, scale)
  let image = canvas.toDataURL('image/png')

  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printRealTimeReport() {
  const { notifySuccess } = useNotifications()
  notifySuccess({
    title: i18n.global.t('notifications.real-time-report')
  })
  let today = moment().subtract(4, 'hours')
  let response = await api.get('/reports/z', {
    startDate: today.format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD')
  })
  let scale = 4
  let report = response?.data
  let config = useConfigStore()
  let canvas = await TicketGenerator.realTimeReport(
    report,
    config.config,
    scale
  )
  let image = canvas.toDataURL('image/png')
  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printTillReport(till, report) {
  const { notifySuccess } = useNotifications()
  notifySuccess({
    title: i18n.global.t('notifications.till-report')
  })
  let response
  if (!report) {
    response = await api.get('/last-shift', {
      tillId: till.id
    })
  }
  let scale = 4
  let canvas = await TicketGenerator.tillReport(
    report || response?.data,
    till.name,
    scale
  )
  let image = canvas.toDataURL('image/png')
  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

function getTargetPrinterId(pickupType) {
  let printerId
  const { config, useSelfPrinter } = useConfigStore()
  let till = useTillStore()
  if (pickupType) {
    if (config.deliveryPrinterId) {
      printerId = config.deliveryPrinterId
    } else {
      let deliveryTillId = config.deliveryTillId
      const cashTill = config.tills.cash.find(t => t.id === deliveryTillId)
      if (cashTill) {
        printerId = till.printerId
      }
    }
  }
  if (!printerId) {
    if (till.selectedCashTill && till.selectedCashTill.printerId) {
      printerId = till.selectedCashTill.printerId
    } else {
      printerId = config.defaultBillPrinter
    }
  }
  if (app.isDataphone && useSelfPrinter) {
    printerId = 'self'
  }
  return printerId
}

async function printXReport(isPreview = false) {
  const { notifySuccess } = useNotifications()
  notifySuccess({ title: i18n.global.t('notifications.x-report') })
  let scale = 4
  let report = (await api.get('/reports/x'))?.data
  let config = useConfigStore()
  let canvas = await TicketGenerator.xReport(
    report,
    config.config,
    scale,
    isPreview
  )
  let image = canvas.toDataURL('image/png')
  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printPayInPayOut(movement) {
  let scale = 4
  let canvas = await TicketGenerator.payInPayOut(movement, scale)
  let image = canvas.toDataURL('image/png')
  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printShiftMovements(tillId, name, movements) {
  const { notifySuccess } = useNotifications()
  notifySuccess({
    title: i18n.global.t('notifications.print-movements')
  })
  if (!movements) {
    let response = await api.get('/reports/open-shift-movements', {
      tillId
    })
    movements = response.data.movements
  }
  let scale = 4
  let config = useConfigStore()
  let canvas = await TicketGenerator.shiftMovements(
    { movements },
    name,
    config.config,
    scale
  )
  let image = canvas.toDataURL('image/png')
  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printBankReceipt(payment) {
  if (!hasDirectPrint()) {
    sync.record('paymentAddedToPrintQueue', payment.id)
    return
  }
  let scale = 4
  let canvas = await TicketGenerator.bankReceipt(payment, scale)
  let image = canvas.toDataURL('image/png')
  let printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

export default {
  printKitchenOrder,
  printKitchenNote,
  printCancelTabTicket,
  printBill,
  printZReport,
  printXReport,
  printTillReport,
  printPayInPayOut,
  printShiftMovements,
  printRealTimeReport,
  printCourierReport,
  printBankReceipt,
  wakeupPrint
}

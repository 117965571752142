<template>
  <div class="w-screen h-screen overflow-hidden flex flex-col">
    <top-bar :show-back="true" @back="router.go(-1)">
      <template #center>
        <div
          v-if="tab"
          class="flex items-center gap-2 font-heading font-bold text-xl"
        >
          {{ tab.tableName }}
          <l-dropdown
            v-model:open="openTabOptions"
            :options="tabOptions"
            placement="bottom"
          >
            <icon
              v-if="tab.open"
              name="arrow-down"
              class="text-v-300"
              @click="openTabOptions = true"
            />
          </l-dropdown>
        </div>
      </template>
    </top-bar>
    <div class="flex w-full h-full overflow-hidden gap-4 z-1">
      <div class="flex flex-col max-w-80 shrink w-full border-n-600 border-r">
        <checkout-tab
          :selected-product-ids="selectedProductsIds"
          class="flex-1 checkout-tab border-b border-n-600"
          :tab-id="tabId"
          @toggle-product="toggleProduct"
        />
        <terrace-surcharge
          v-if="tableHasSurcharge"
          class="mx-4"
          :tab="tab"
          @toggle="onTerraceSurchargeToggled"
        />
        <balance class="mx-4" :tab-id="tabId" size="large" />
      </div>
      <div class="flex justify-center w-full overflow-y-auto">
        <div class="mb-4 w-full max-w-96">
          <div class="w-full max-w-96 py-7 flex flex-col">
            <div
              class="flex items-center justify-between pb-4 font-heading text-n-0 w-full"
            >
              <div class="font-medium">
                {{ $t('checkout.total') }}
              </div>
              <div v-if="selectedBill" class="text-xl font-bold">
                {{ $filters.currency(selectedBill.total) }}
              </div>
            </div>
            <charge-calculator
              :payment-methods="paymentMethods"
              :initial-pending="(selectedBill && selectedBill.pending) || 0"
              :show-probable-amounts="false"
              keypad-mode="full"
              display-mode="pending"
              :autofill="false"
              @change-payment-method="selectPaymentMethod"
              @calculator-change="calculatorChange"
            />
            <div class="mt-auto">
              <l-button
                class="w-full"
                :disabled="isCharging || !canCharge"
                @click="charge"
              >
                {{ $t('checkout.charge') }}
                {{ currency(calculatorValues.toPay) }}
              </l-button>
            </div>
            <div class="grid grid-cols-2 gap-2 mt-2">
              <bill-discount
                v-model:discount="discount"
                :disabled="isExisting(selectedBillId)"
                :original-total="selectedBill && selectedBill.originalTotal"
                :tab-id="tabId"
              />
              <l-button v-if="selectedBill" @click="printBill">
                {{ $t('checkout.print') }}
              </l-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex max-w-80 w-full">
        <bills-list
          class="p-6"
          :current-bill-id="currentBill && currentBill.id"
          :bills="bills"
          :selected-bill-id="selectedBillId"
          :tab-id="tabId"
          @bill-selected="id => (selectedBillId = id)"
          @invalidate="invalidateAndRegenerate"
          @cancel-invoice="cancelInvoice"
          @generate-invoice="id => (generateInvoiceBillId = id)"
          @send-invoice="id => (generateInvoiceBillId = id)"
        />
      </div>
    </div>
    <generate-invoice
      v-if="generateInvoiceBillId"
      :tab-id="tabId"
      :bill-id="generateInvoiceBillId"
      @new-bill-id="id => (selectedBillId = id)"
      @close="generateInvoiceBillId = ''"
    />
  </div>
</template>

<script setup lang="ts">
import ChargeCalculator from '@/components/ChargeCalculator/ChargeCalculator.vue'
import TicketPrinter from '@/ticketPrinter.js'
import BillsList from '@/components/checkout/BillsList.vue'
import Balance from '@/components/core/Balance.vue'
import CheckoutTab from './CheckoutTab.vue'
import TopBar from '@/components/core/TopBar.vue'
import BillDiscount from '@/components/checkout/BillDiscount.vue'
import { Icon, useDialog } from '@last/core-ui/paprika'
import { GenerateInvoice } from '@/components/invoices'
import TerraceSurcharge from '@/components/checkout/TerraceSurcharge.vue'
import { useTablesStore } from '@/store/tables'
import { useTabsStore } from '@/store/tabs'
import { usePromotionsStore } from '@/store/promotions'
import { storeToRefs } from 'pinia'
import { useTabs } from '@/composables/useTabs'
import { computed, onMounted, ref, watch } from 'vue'
import { useCheckout } from '@/composables/useCheckout'
import { LButton, LDropdown } from '@last/core-ui/paprika'
import type { CalculatorPayload } from '@/components/Calculator/CalculatorUtils'
import { useMoney } from '@/composables/useMoney'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'

const route = useRoute()
const router = useRouter()

const tabId = ref<string>(
  Array.isArray(route.params?.tabId)
    ? route.params.tabId[0]
    : route.params.tabId
)
const openTabOptions = ref(false)

const tablesStore = useTablesStore()
const tabsStore = useTabsStore()
const promotionsStore = usePromotionsStore()
const dialog = useDialog()

const { tables } = storeToRefs(tablesStore)
const { isTabOpen } = storeToRefs(tabsStore)
const { getTabGlobalPromotion } = storeToRefs(promotionsStore)

const {
  addBill,
  removeBill,
  updateTerraceSurchargePercentage,
  regenerateBill
} = tabsStore

const { tab, getBills, getAllProducts, closeTab } = useTabs(tabId.value)

const calculatorValues = ref<CalculatorPayload>({
  toPay: 0,
  pending: 0,
  change: 0,
  tip: 0
})

const {
  selectedBillId,
  currentBill,
  selectedProductsIds,
  paymentMethods,
  selectPaymentMethod,
  isCharging,
  bills,
  charge,
  canCharge,
  selectedBill,
  checkoutCompleteWithPayments
} = useCheckout(tabId.value, 'normalCheckout', calculatorValues)

const { currency } = useMoney()
const { t } = useI18n()
const currentDiscount = ref()
const generateInvoiceBillId = ref<string>('')

const tabOptions: Option[] = [
  {
    label: t('checkout.delete'),
    value: 'delete',
    onClick: () => closeTab()
  }
]

const tabGlobalPromotion = computed(() =>
  getTabGlobalPromotion.value(tabId.value)
)

const discount = computed({
  get() {
    if (isExisting(selectedBillId.value)) {
      return selectedBill.value.discount || {}
    }
    return currentDiscount.value
  },
  set(discount) {
    promotionsStore.updateTabPromotion({
      tabId: tabId.value,
      promotion: {
        discountType: discount.type,
        discountAmount: discount.amount,
        name: discount.name,
        id: discount.promotionId,
        global: discount.global
      }
    })
    currentDiscount.value = discount
  }
})

const tableHasSurcharge = computed(() => {
  if (!tab || !tab.value) return false
  let table = tables.value[tab.value.tables[0]]

  return table && table.terraceSurchargePercentage > 0
})

function calculatorChange(value: CalculatorPayload) {
  calculatorValues.value = value
}

watch(checkoutCompleteWithPayments, complete => {
  let tabProducts = getAllProducts.value
  if (complete && tabProducts.length > 0) {
    closeTab()
  }
})

onMounted(() => {
  if (!isTabOpen.value(tabId.value)) {
    router.push({ name: 'pos' })
  }
  if (bills.value && bills.value.length > 0) {
    selectedBillId.value = bills.value[0].id
  }
  if (tabGlobalPromotion.value) {
    currentDiscount.value = {
      type: tabGlobalPromotion.value.discountType,
      amount: tabGlobalPromotion.value.discountAmount,
      promotionId: tabGlobalPromotion.value.promotionId,
      concept: tabGlobalPromotion.value.concept
    }
  }
})

function printBill() {
  addBill({ tabId: tabId.value, bill: selectedBill.value })
  TicketPrinter.printBill(selectedBill.value)
  selectedProductsIds.value = []
  currentDiscount.value = {}
}

function invalidateAndRegenerate(billId: string) {
  dialog({
    title: t('checkout.invalidate-bill'),
    content: t('checkout.invalidate-bill-content'),
    mainLabel: t('ctas.yes'),
    onConfirm: () => {
      removeBill({ tabId: tabId.value, billId })
      if (currentBill.value) {
        selectedBillId.value = currentBill.value.id
      } else if (bills.value && bills.value.length > 0) {
        selectedBillId.value = bills.value[0].id
      }
    }
  })
}

function isExisting(billId: string): boolean {
  return getBills.value.map(bill => bill.id).includes(billId)
}

function toggleProduct(productId: { id: string; uniqueId: string }): void {
  if (
    selectedProductsIds.value
      .map(id => id.uniqueId)
      .includes(productId.uniqueId)
  ) {
    selectedProductsIds.value = selectedProductsIds.value.filter(
      id => id.uniqueId != productId.uniqueId
    )
  } else {
    selectedProductsIds.value = [...selectedProductsIds.value, productId]
  }
  selectedBillId.value = currentBill.value.id
}

async function cancelInvoice(billId: string) {
  selectedBillId.value = regenerateBill({
    tabId: tabId.value,
    billId: billId,
    customerCompany: undefined
  })
}

function onTerraceSurchargeToggled(newValue: boolean): void {
  updateTerraceSurchargePercentage({
    tabId: tabId.value,
    enabled: newValue
  })
}
</script>

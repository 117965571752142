import { rum } from './monitoring'
import pinia from '@/store'
import AppRoot from '@/AppRoot.vue'
import App from './app'
import { router } from '@/router'
import { createApp } from 'vue'
import { ManagerConfirmationPlugin } from '@/plugins/managerConfirmation'
import { horizontalScrollDirective } from './directives'
import sync from '@/sync/service'
import { LDialogPlugin, LNotificationPlugin } from '@last/core-ui/paprika'
import jobs from '@/jobs'
import clickOutside from '@last/core-ui/v3/directives/clickOutside'
import localDb from '@/localDb'
import { App as CapacitorApp } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { Network } from '@capacitor/network'
import { useCatalogStore } from '@/store/catalog'
import { useAuthStore } from '@/store/auth'
import { useStatusStore } from '@/store/status'
import { usePromotionsStore } from '@/store/promotions'
import { useCouriersStore } from '@/store/couriers'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import appStorage from '@/appStorage'
import { vLongpress } from '@last/core-ui/v3/directives/v-use-longpress'
import { register as registerSwiper } from 'swiper/element/bundle'
import i18n from '@/i18n'
import { VTaphint } from '@last/core-ui/v3/directives/v-taphint'
import {
  trackerPlugin,
  PosthogTracker,
  ConsoleTracker
} from '@last/core-ui/paprika'
import { UpdateModalPlugin } from './plugins/updateVersion'
import { createFilters } from './filters'
import { Catalogs } from './types'
import images from './images'
import './style.css'

const app = createApp({
  name: 'LastPOS',
  ...AppRoot
})

app.config.performance = import.meta.env.DEV

app.directive('taphint', VTaphint)
app.directive('horizontal-scroll', horizontalScrollDirective)
app.directive('click-outside', clickOutside)
app.directive('use-longpress', vLongpress)
app.use(pinia)
app.use(i18n)
app.use(router)
app.use(ManagerConfirmationPlugin)
app.use(UpdateModalPlugin)
app.use(LDialogPlugin)
app.use(LNotificationPlugin)

rum.setRouter(router)
if (import.meta.env.PROD) {
  app.config.errorHandler = err => {
    rum.addError(err)
  }
}

if (import.meta.env.VITE_POSTHOG_API_KEY) {
  app.use(trackerPlugin, {
    tracker: new PosthogTracker(import.meta.env.VITE_POSTHOG_API_KEY)
  })
} else {
  app.use(trackerPlugin, {
    tracker: new ConsoleTracker()
  })
}

const filters = createFilters(pinia)
app.config.globalProperties.$filters = filters

pinia.use(({ store }) => {
  store.$confirm = app.config.globalProperties.$confirm
})

registerSwiper()

app.mount('#app')

Network.addListener('networkStatusChange', status => {
  const statusStore = useStatusStore(pinia)
  if (status.connected) {
    statusStore.updateNetworkStatus(true)
  } else {
    statusStore.updateNetworkStatus(false)
  }
})

if (window.Cypress) {
  const auth = useAuthStore(pinia)
  window.pinia = { auth }
}

async function getOldStorage() {
  const oldStorage = (await appStorage.getItem('vuex')) || '{}'
  let storeData
  if (typeof oldStorage === 'string') {
    storeData = JSON.parse(oldStorage || '{}')
  } else {
    storeData = oldStorage
  }
  return { auth: storeData?.auth, config: storeData?.config }
}

/*
async function removeappxData() {
  return appStorage.removeItem('vuex')
}
*/

async function init() {
  App.notifyAppReady()
  const auth = useAuthStore(pinia)
  const config = useConfigStore(pinia)
  const oldStorage = await getOldStorage()
  const oldAuthData = oldStorage.auth
  if (oldAuthData) {
    auth.locationId = oldAuthData.locationId
    auth.accessToken = oldAuthData.accessToken
    config.device.id = oldStorage.config.device.id
    // Comment for now until we are sure that a rollback is not needed
    //await removeappxData()
  }
  let tabsTables: any
  const tabs = useTabsStore(pinia)
  if (!sync.isInitialized()) {
    const tabKeys = Object.keys(tabs).map(key => `tabs:${key}`)
    tabsTables = await localDb.loadTables(tabKeys)
    tabsTables = Object.keys(tabsTables).reduce((res, key) => {
      res[key.replace('tabs:', '')] = tabsTables[key]
      return res
    }, {} as any)
  }
  if (!sync.isInitialized()) {
    tabs.replaceTabs(tabsTables)
  }
  const catalog = useCatalogStore(pinia)
  appStorage.getItem<Catalogs>('catalogs').then(async catalogs => {
    if (catalogs) {
      await catalog.refreshCatalogs(catalogs)
    }
    catalog.refreshCatalogs()
  })
  config.refreshConfig()
  const locationId = auth.locationId
  const status = useStatusStore(pinia)
  await status.updateCurrentStatus()
  if (locationId) {
    //mqtt.subscribe(locationId)
    auth.refreshCurrentLocation()
    status.refreshErrorMessages()
    auth.refreshBillingStatus()
  }
  images.removeOldCache()
  const promotions = usePromotionsStore(pinia)
  const couriers = useCouriersStore(pinia)
  CapacitorApp.addListener('appStateChange', state => {
    if (state.isActive) {
      sync.resync()
      catalog.refreshCatalogs()
      if (config.config.organizationConfig.promotions) {
        promotions.refreshPromotions()
      }
      if (config.config.organizationConfig.deliveryFleet) {
        couriers.refreshCouriers()
      }
    }
  })
  jobs.start()
  SplashScreen.hide()
}

if (window.location.href.includes('refreshLocalStorage')) {
  localStorage.clear()
}

init()

export default app

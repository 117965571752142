<template>
  <div>
    <l-dropdown
      v-model="selected"
      v-model:open="showDropdown"
      :options="dropdownOptions"
      placement="bottom-end"
      :allow-remove="false"
      :search="false"
      :multiselect="false"
      @update:model-value="selectOption"
    >
      <l-button
        class="relative"
        size="small"
        icon="dots"
        @click="showDropdown = !showDropdown"
      />
    </l-dropdown>
    <move-products-between-tabs
      v-if="selected === 'move-products'"
      :from-tab-id="tabId"
      class="absolute left-0 top-0 z-20"
      @products-moved="goToTab"
      @close="closePopUps"
    />
    <resend-kitchen-orders
      v-if="selected === 'resend-kitchen-orders'"
      :tab-id="tabId"
      @close="closePopUps"
    />
    <send-kitchen-note
      v-if="selected === 'kitchen-note'"
      :tab-id="tabId"
      @close="closePopUps"
    />
  </div>
</template>
<script lang="ts" setup>
import { LDropdown, LButton } from '@last/core-ui/paprika'
import MoveProductsBetweenTabs from './MoveProductsBetweenTabs.vue'
import ResendKitchenOrders from './ResendKitchenOrders.vue'
import SendKitchenNote from './SendKitchenNote.vue'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import type { Option } from '@last/core-ui/paprika/components/dropdown/types'
import { computed } from 'vue'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'

defineProps<{
  tabId: string
}>()

const { t } = useI18n()
const router = useRouter()

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const dropdownOptions = computed<Option[]>(() => {
  const options = [
    {
      icon: 'arrow-left-right',
      label: t('ordering.move-products'),
      value: 'move-products'
    },
    { icon: 'note', label: t('ordering.kitchen-note'), value: 'kitchen-note' }
  ]

  if (config.value.enableKitchenOrders) {
    options.splice(1, 0, {
      icon: 'arrows-counter-clockwise',
      label: t('resend-kitchen-orders.title'),
      value: 'resend-kitchen-orders'
    })
  }
  return options
})
const showDropdown = ref(false)
const selected = ref<string>('')

const selectOption = (value: string[]) => {
  showDropdown.value = false
  selected.value = value[0]
}

const closePopUps = () => {
  selected.value = ''
}

const goToTab = (tabId: string) => {
  closePopUps()
  router.push({ name: 'orderManagement', params: { tabId } })
}
</script>

<template>
  <div :style="style">
    <tippy
      ref="contextMenu"
      :interactive="true"
      :arrow="false"
      trigger="manual"
      placement="top-start"
      :append-to="() => appendTarget"
    >
      <template v-if="table.type !== 'wall'" #content>
        <TableContextMenu
          :table-state="tableState"
          :table="table"
          @close="hideContextMenu"
          @merge-tabs="$emit('mergeTabs', $event)"
          @assign-table="$emit('assignTable', $event)"
        />
      </template>
      <div
        v-use-longpress.disableMouse
        :style="[
          getAnimationStyle,
          { width: props.table.width + 'px', height: props.table.height + 'px' }
        ]"
        class="relative flex items-center justify-center cursor-pointer"
        :class="[
          {
            'border !border-v-300': isSelected && !isBlocked,
            'border !border-r-300': isBlocked,
            'rounded-lg': table.type === 'rectangle',
            'rounded-[200px]': table.type === 'circle',
            'bg-n-900 text-n-200': table.type === 'wall',
            'border border-n-0': pendingKitchenOrders(table),
            'text-n-0 bg-b-500': tableState === 'occupied' && !hasReservation,
            'text-n-0 bg-dual-b-y': tableState === 'occupied' && hasReservation,
            'bg-r-300 text-n-0': tableState === 'toPay' && !hasReservation,
            'text-n-0 bg-dual-r-y': tableState === 'toPay' && hasReservation,
            'bg-y-500 text-n-0': tableState === 'reserved',
            'text-n-800 bg-n-0': tableState === 'empty',
            ['moving']:
              table.type !== 'wall' &&
              wiggleEnabled &&
              !isBlocked &&
              !isSelected
          }
        ]"
        @longpress="showContextMenu()"
        @contextmenu.prevent="showContextMenu()"
        @click="$emit('selectedTable')"
      >
        <div class="w-full h-full p-0.5">
          <div class="flex flex-col justify-center w-full h-full">
            <div
              class="flex items-center justify-center overflow-hidden"
              :class="[table.height > 50 ? 'mb-0.5' : '-mb-0.5']"
            >
              <div
                class="text-center uppercase font-heading line-clamp-1"
                :class="{
                  'text-n-200 text-xs': table.type === 'wall',
                  'text-xs font-bold': table.type !== 'wall',
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                <span>{{ name }}</span>
                <span v-if="groupedTableNames.length > 0" class="font-normal">
                  {{ ', ' + groupedTableNames.join(', ') }}
                </span>
              </div>
            </div>
            <template v-if="mode === 'time'">
              <div
                v-if="table.tabIds.length > 0"
                class="flex flex-row justify-center items-center text-[0.625rem]"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                {{ elapsedTime(tabsStore.getLastInteraction(table.tabIds[0])) }}
              </div>
              <div
                v-else-if="hasReservation"
                class="flex flex-row justify-center items-end text-[0.625rem]"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                <div>{{ timeUntil(reservationTime) }}</div>
              </div>
            </template>

            <template v-else-if="mode === 'diners'">
              <div
                v-if="seats > 0"
                class="flex items-center justify-center text-[0.625rem]"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                {{ seats }}
              </div>
            </template>

            <template v-else-if="mode === 'amount'">
              <div
                v-if="total > 0"
                class="flex items-center justify-center text-[0.625rem] text-center"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                {{ currency(total) }}
              </div>
            </template>

            <div
              v-if="pendingKitchenOrders(table)"
              class="absolute bg-n-0 rounded-full w-2.5 h-2.5 mr-[-6px] mt-[-5px]"
              :style="getTopCornerStyles(table)"
            >
              <div class="absolute inset-[1px] rounded-full bg-y-500" />
            </div>

            <div
              v-if="table?.tabIds?.length > 1"
              class="absolute w-2.5 h-2.5 scale-75"
              :style="getBottomCornerStyles(table)"
            >
              <l-badge type="small" :value="table?.tabIds?.length" />
            </div>
          </div>
        </div>
      </div>
    </tippy>
  </div>
</template>

<script setup lang="ts">
import { LBadge } from '@last/core-ui/paprika'
import moment from 'moment'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import { useReservationsStore } from '@/store/reservations'
import { storeToRefs } from 'pinia'
import { useTabs } from '@/composables/useTabs'
import { computed, onBeforeUnmount, onMounted, ref, StyleValue } from 'vue'
import { Tippy } from 'vue-tippy'
import TableContextMenu from './TableContextMenu.vue'
import { Tab, Table } from '@/types'
import lastUtils from '@last/core/src/lastUtils'
import type { TableStates } from '@/components/tables/table.utils'

type Props = {
  table: Table
  mode: string
  floorplanId: string
  groupedTableNames: string[]
  wiggleEnabled?: boolean
  isSelected?: boolean
  isBlocked?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  wiggleEnabled: false,
  isSelected: false,
  isBlocked: false
})

defineEmits(['selectedTable', 'mergeTabs', 'assignTable'])

const tabsStore = useTabsStore()
const configStore = useConfigStore()

const { getReservation } = useReservationsStore()

const { tabs, kitchenOrders } = storeToRefs(tabsStore)
const { config } = storeToRefs(configStore)

const currentDate = ref<Date>(new Date())
const dateInterval = ref()
const contextMenu = ref<typeof Tippy>()

const appendTarget = ref(document.body)

onMounted(() => {
  dateInterval.value = setInterval(() => {
    currentDate.value = new Date()
  }, 60 * 1000)
})

onBeforeUnmount(() => {
  if (dateInterval.value) {
    clearInterval(dateInterval.value)
  }
})

const tab = computed(() => {
  return (tabs.value as any)[props.table.tabIds[0]] as Tab | undefined
})

function currency(value: number): string {
  return lastUtils.currencyFilter(value, config.value.currencyCode || 'EUR')
}

const tableState = computed<TableStates>(() => {
  const tableHasTab = hasTab.value
  const tableHasReservation = hasReservation.value
  const tableHasBills = hasBills.value

  if (!tableHasTab && !tableHasReservation && !tableHasBills) {
    return 'empty'
  } else if (tableHasBills) {
    return 'toPay'
  } else if (tableHasTab) {
    return 'occupied'
  } else if (tableHasReservation) {
    return 'reserved'
  } else {
    return 'empty'
  }
})

const style = computed(() => {
  return {
    position: 'absolute',
    width: props.table.width + 'px',
    height: props.table.height + 'px',
    left: props.table.x + 'px',
    top: props.table.y + 'px'
  } as Partial<StyleValue>
})

const name = computed(() => {
  if (
    tab.value &&
    tab.value.lang &&
    tab.value.lang !== configStore.config.locationCountryCode
  ) {
    return `${tab.value.lang} - ${props.table.name}`
  }
  return props.table.name
})

const seats = computed(() => {
  if (tab.value) {
    return tab.value.seats?.length || 0
  }
  let reservation = getReservation(props.table.id, currentDate.value)
  if (reservation) {
    return reservation.diners || 0
  }
  return 0
})

const total = computed(() => {
  return props.table.tabIds.reduce((total, id) => {
    const { getTotal } = useTabs(id)
    return total + (getTotal.value ?? 0)
  }, 0)
})

function pendingKitchenOrders(table: Table) {
  if (!config.value.enableKitchenOrders) return false
  return table.tabIds.some(tabId => {
    const { getUnsentProducts } = useTabs(tabId)
    return (
      getUnsentProducts.value.length > 0 ||
      tabs.value[tabId].kitchenOrders
        .map((id: string) => kitchenOrders.value[id])
        .filter(order => !order.printedTime && order.copies > 0).length > 0
    )
  })
}

const hasTab = computed(() => {
  return props.table.tabIds.length > 0
})

const hasBills = computed(() => {
  if (props.table.tabIds.length === 0) return false

  return props.table.tabIds.some(tabId => {
    return (tabs.value[tabId] as Tab)?.billingStartedTime
  })
})

const hasReservation = computed(() => {
  const reservation = getReservation(props.table.id, currentDate.value)
  return !!reservation && !reservation.cancelled
})

const reservationTime = computed(() => {
  let reservation = getReservation(props.table.id, currentDate.value)
  if (reservation) {
    return reservation.dateTime || ''
  }

  return ''
})

function elapsedTime(date: Date) {
  if (date) {
    const now = moment(currentDate.value)
    const start = moment(date)
    const duration = moment.duration(Math.max(now.diff(start), 0))

    const minutes = Math.floor(duration.asMinutes())
    const roundMinutes =
      Array.from(`${minutes % 60}`).length <= 1
        ? `0${minutes % 60}`
        : minutes % 60

    return `${Math.floor(minutes / 60)}:${roundMinutes}`
  }
}

function getTopCornerStyles(table: Table): Partial<StyleValue> {
  if (table.type === 'rectangle') {
    return {
      top: '1px',
      right: '1px'
    }
  } else if (table.type === 'circle') {
    return {
      top: `${calculateCornerPositionForRoundedTables(table)}px`,
      right: `${calculateCornerPositionForRoundedTables(table)}px`
    }
  }

  return {}
}

function getBottomCornerStyles(table: Table): Partial<StyleValue> {
  if (table.type === 'rectangle') {
    return {
      bottom: '-2px',
      right: '-2px'
    }
  } else if (table.type === 'circle') {
    return {
      bottom: `${calculateCornerPositionForRoundedTables(table) - 4}px`,
      right: `${calculateCornerPositionForRoundedTables(table) - 4}px`
    }
  }

  return {}
}

function calculateCornerPositionForRoundedTables(table: Table): number {
  const hipotenuse = Math.min(table.width, table.height) / 2
  const cathetus = Math.sqrt((hipotenuse * hipotenuse) / 2)

  return hipotenuse - cathetus
}

function timeUntil(date: string) {
  if (date) {
    const now = moment(currentDate.value)
    const start = moment(date)
    const duration = moment.duration(start.diff(now))
    if (duration.asHours() >= 0) {
      return (
        Math.floor(duration.asHours()) +
        moment.utc(duration.asMilliseconds()).format(':mm')
      )
    } else {
      let minus = '-'
      if (duration.asHours() < -1) minus = ''
      return (
        minus +
        Math.ceil(duration.asHours()) +
        ':' +
        String(-duration.minutes()).padStart(2, '0')
      )
    }
  }
}

function showContextMenu() {
  contextMenu.value?.show()
}

function hideContextMenu() {
  contextMenu.value?.hide()
}

const getAnimationStyle = computed(() => {
  if (props.table.type === 'wall' || !props.wiggleEnabled) return ''
  return {
    animationDuration: 0.3 + 's',
    animationDelay: -(Math.random() + 0.3) + 's',
    ['--table-animation-variant' as string]:
      getAngle(Math.max(props.table.width, props.table.height)) + 'deg'
  }
})

function getAngle(size: number): number {
  const maxAngle = 6
  const maxSize = 200
  const stepSize = maxSize / maxAngle

  const angle = maxAngle - Math.round(size / stepSize)

  return angle <= 2 ? 2 : angle >= maxAngle ? maxAngle : angle
}
</script>

<style scoped>
:root {
  --table-animation-variant: 1deg;
}

.moving {
  animation-name: wiggle;
  animation-iteration-count: infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(var(--table-animation-variant));
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(calc(var(--table-animation-variant) * -1));
    animation-timing-function: ease-out;
  }
  100% {
    transform: rotate(var(--table-animation-variant));
    animation-timing-function: ease-out;
  }
}
</style>

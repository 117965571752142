<template>
  <l-modal
    size="small"
    :title="title"
    :button-text="$t('ctas.confirm')"
    :button-enabled="isComplete"
    :full-height="false"
    @close="emit('close')"
    @action="saveTab()"
  >
    <div class="flex flex-col">
      <l-field :label="$t('new-tab.name-label')">
        <l-input
          v-model="newTab.name"
          size="small"
          :placeholder="$t('new-tab.name-placeholder')"
        />
      </l-field>
      <new-tab-seats-selector v-model="newTab.seats" @save="saveTab" />
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { LInput, LField, LModal } from '@last/core-ui/paprika'
import NewTabSeatsSelector from '@/components/tabs/NewTabSeatsSelector.vue'
import { useTabsStore } from '@/store/tabs'
import { useConfigStore } from '@/store/config'
import { storeToRefs } from 'pinia'
import { defineProps, withDefaults, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'

const { t } = useI18n()
const router = useRouter()

type Table = any
type Customer = any

const props = withDefaults(
  defineProps<{
    table?: Table
    customer?: Customer
  }>(),
  {
    isActive: false,
    table: undefined,
    customer: undefined
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'tabCreated', tabId: string): void
}>()

const tabsStore = useTabsStore()
const configStore = useConfigStore()

const { config } = storeToRefs(configStore)
const { openTab, openTabWithCustomer } = tabsStore

const newTab = ref<{
  lang: string
  name: string | null
  seats: number | null
}>({
  lang: 'ES',
  name: null,
  seats: null
})

onMounted(() => {
  newTab.value.lang = config.value.locationCountryCode
  if (!config.value.organizationConfig.seatSelector) {
    newTab.value.seats = 0
    saveTab()
  }
})

const isComplete = computed(() => newTab.value.seats != null)

const title = computed(() => {
  if (props.table) {
    return t('new-tab.new-tab-table', { name: props.table.name })
  }
  return t('new-tab.new-tab')
})

function saveTab() {
  if (!isComplete.value) return
  let tabId = null
  if (props.customer) {
    tabId = openTabWithCustomer({
      tableId: props.table?.id || null,
      tab: { ...newTab.value, customerInfo: props.customer },
      customer: props.customer
    })
  } else {
    tabId = openTab({
      tableId: props.table?.id || null,
      tab: newTab.value
    })
  }
  emit('tabCreated', tabId)
  emit('close')
  router.push({ name: 'orderManagement', params: { tabId } })
}
</script>
